<template>
  <div>
    <section id="login">
      <form ref="form" @submit.prevent="submit">
        <v-container class="login_container">
          <v-row class="d-flex flex-column justify-center align-center">
            <h3 class="login-card__header">Forgot Password</h3>
            <s-card class="login-card col-12" outlined>
              <v-row>
                <v-col>
                  <s-text-field
                    name="email"
                    label="Email"
                    filled
                    required
                    v-model="email"
                    :error-messages="getErrors('email', $v.email)"
                    @blur="$v.value.email.$touch()"
                  />
                </v-col>
              </v-row>
              <s-btn
                testButton="submit-button"
                type="submit"
                color="primary"
                class="ma-0"
                elevation="0"
                block
                :loading="isLoading"
                :disabled="$v.$invalid"
              >
                Submit
              </s-btn>
            </s-card>
            <div class="pt-3 max-width">
              Remember Password ?,&nbsp;
              <s-link class="forgotpass-link" :to="{ name: 'login' }">
                Sign in
              </s-link>
            </div>
          </v-row>
        </v-container>
      </form>
    </section>
  </div>
</template>

<script>
import getErrors from '@/mixins/vuelidateErrors'
import { email, required } from 'vuelidate/lib/validators'
export default {
  mixins: [getErrors],
  name: 'ForgotPassword',
  data() {
    return {
      email: '',
      isLoading: false,
    }
  },
  validations: {
    email: {
      email,
      required,
    },
  },
  methods: {
    submit() {
      if (this.$v.$invalid) {
        this.$v.$touch()
        return
      }
      this.isLoading = true
      this.$store
        .dispatch('auth/forgotPassword', {
          email: this.email,
        })
        .then(() => {
          this.$router.push({ name: 'resetPassword' })
        })
        .finally(() => {
          this.isLoading = false
        })
    },
  },
}
</script>

<style lang="scss" scoped>
@import '@/assets/styles/_variables.scss';
@import '@/assets/styles/_mixins.scss';
#login {
  @include center-children;

  & .login_container {
    @include auth-card-dimension;
  }
}

#logo {
  @include auth-logo;
}

.login-card {
  @include auth-card;
}

.signup-link {
  margin-top: 18px;
}

.forgotpass-link {
  @include black-link;
}
.login-card__header {
  @include auth-header;
}
</style>
